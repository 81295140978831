import { Controller } from "stimulus";
import { Modal } from "bootstrap";

document.addEventListener('DOMContentLoaded', function() {
  const couponCodeInput = document.querySelector('[data-job-offers--summary-target="couponCodeText"]');
  const applyButton = document.querySelector('#apply-coupon-button');

  if(couponCodeInput) {
    couponCodeInput.addEventListener('input', function() {
      applyButton.disabled = couponCodeInput.value.trim() === '';
    });
    couponCodeInput.addEventListener('keydown', function(e) {
      if(e.key === 'Enter') {
        e.preventDefault();
        applyButton.click();
      }
    });
  }
});

export default class extends Controller {
  static targets = [
    "productErrorMsg",
    "purchaseBillingForm",
    "hiddenProductIdInput",
    "purchaseSummaryBox",
    "previewRegularJobOffer",
    "previewJobOfferButton",
    "previewFeaturedJobOffer",
    "purchaseBillingFormErrorMsg",
    "postButton",
    "postButtonSidebar",
    "defaultProduct",
    "couponCode",
    "couponCodeText",
    "couponError",
  ];

  connect() {
    this.selectedProduct = null;
    const performanceEntries = performance.getEntriesByType("navigation");

    if (performanceEntries[0].type === "back_forward") {
        window.location.reload();
    }

    this.defaultProductTarget.checked = this.companyHasNoStock();
    if(this.defaultProductTarget.checked) {
      this.productSelected({ currentTarget: this.defaultProductTarget });
    }
  }

  addProduct(event) {
    event.preventDefault();

    this.hiddenProductIdInputTarget.value = parseInt(
      event.currentTarget.dataset.productId
    );

    this.purchaseBillingFormTarget.requestSubmit();
    this.showSpinner();
  }

  onPostSuccess(event) {
    const [data, ,] = event.detail;
    if (data.url != undefined) {
      window.location = data.url;
    }
  }

  onPostError(event) {
    const [data, ,] = event.detail;
    const billingInfoValid = data.billing_info_valid;
    this.show(this.purchaseBillingFormErrorMsgTarget);
    window.scrollTo(0, 0)

    if(!billingInfoValid) {
      this.showBillingInformationModalForm();
    }
  }

  showProductErrorMsg(event) {
    event.preventDefault();

    this.show(this.productErrorMsgTarget);
    this.disableButtons();
  }

  productSelected(event) {
    document.querySelectorAll('.discount-line').forEach(this.hide);

    const element = event.currentTarget;
    this.selectedProduct = element;

    const linkStockNotAvailable = element.dataset.linkStockNotAvailable === "true";
    const linkText = element.dataset.linkText;
    const linkProductId = element.dataset.linkProductId;
    const linkPostNowUrl = element.dataset.linkPostNowUrl;
    const linkAction = "click->job-offers--summary#addProduct";
    const disableLink = "click->job-offers--summary#disableButtons";
    const showSpinner = "click->job-offers--summary#showSpinner";

    this.enableButtons();

    this.couponCodeTarget.textContent = '';
    this.couponErrorTarget.textContent = '';

    if (linkStockNotAvailable) {
      this.showSummaryBox(element);
      this.postButtonTarget.setAttribute("href", "#");
      this.postButtonTarget.setAttribute("data-product-id", linkProductId);
      this.postButtonTarget.setAttribute("data-action",[linkAction, disableLink].join(" "));
      this.postButtonTarget.removeAttribute("data-method");
      this.postButtonSidebarTarget.setAttribute("href", "#");
      this.postButtonSidebarTarget.setAttribute("data-product-id",linkProductId);
      this.postButtonSidebarTarget.setAttribute("data-action",[linkAction, disableLink].join(" "));
      this.postButtonSidebarTarget.removeAttribute("data-method");
    } else {
      this.hide(this.purchaseSummaryBoxTarget);
      this.postButtonTarget.setAttribute("href", linkPostNowUrl);
      this.postButtonTarget.setAttribute("data-action", [showSpinner, disableLink].join(" "));
      this.postButtonTarget.setAttribute("data-method", "post");
      this.postButtonTarget.removeAttribute("data-product-id");
      this.postButtonSidebarTarget.setAttribute("href", linkPostNowUrl);
      this.postButtonSidebarTarget.setAttribute("data-action", [showSpinner, disableLink].join(" "));
      this.postButtonSidebarTarget.setAttribute("data-method", "post");
      this.postButtonSidebarTarget.removeAttribute("data-product-id");
    }

    this.postButtonTarget.innerHTML = linkText;
    this.postButtonSidebarTarget.innerHTML = linkText;
  }

  showSummaryBox(element) {
    const title = element.dataset.productTitle;
    const price = element.dataset.productPrice;
    const discount = element.dataset.productPrice;
    const vat = element.dataset.productVat;
    const vatPrice = element.dataset.productVatPrice;
    const totalPrice = element.dataset.productTotalPrice;

    this.purchaseSummaryBoxTarget.querySelector("#product-title").innerHTML = title;
    this.purchaseSummaryBoxTarget.querySelector("#product-vat").innerHTML = vat;
    this.purchaseSummaryBoxTarget.querySelector("#product-vat-price").innerHTML = vatPrice;
    this.purchaseSummaryBoxTarget.querySelector("#product-price").innerHTML = price;
    this.purchaseSummaryBoxTarget.querySelector("#product-total-price").innerHTML = totalPrice;
    this.show(this.purchaseSummaryBoxTarget)
  }

  regularJobOffer() {
    this.hide(this.previewFeaturedJobOfferTarget);
    this.show(this.previewRegularJobOfferTarget);
    this.previewJobOfferButtonTarget.setAttribute("data-bs-target", "#offerPreview");
  }

  featuredJobOffer() {
    this.hide(this.previewRegularJobOfferTarget);
    this.show(this.previewFeaturedJobOfferTarget);
    this.previewJobOfferButtonTarget.setAttribute("data-bs-target", "#featuredOfferPreview");
  }

  disableButtons() {
    this.postButtonTarget.classList.add("disabled");
    this.postButtonSidebarTarget.classList.add("disabled");
  }

  enableButtons() {
    this.hide(this.productErrorMsgTarget);
    this.postButtonTarget.classList.remove("disabled");
    this.postButtonSidebarTarget.classList.remove("disabled");
  }

  showSpinner() {
    const spinner = `<div class="d-flex justify-content-center" style="padding: 0.25rem 1.5rem;">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                     </div>`;

    this.postButtonTarget.innerHTML = spinner;
    this.postButtonSidebarTarget.innerHTML = spinner;
    this.disableProductInputs();
  }

  applyCoupon() {
    const productId = this.postButtonTarget.getAttribute("data-product-id");
    const couponCode = this.couponCodeTextTarget.value;
    const locale = window.location.pathname.split('/')[1];
    const localePath = locale === 'en' ? '/en' : '';

    const url = `${localePath}/employer/discounts/${couponCode}?product_id=${productId}`;

    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if(!response.ok) {
          throw new Error('Network error calling discounts');
        }
        return response.json();
      })
      .then(data => {
        const { valid, code, price, amount, vat, total_price, discount_description, message } = data;
        if (valid) {
          this.updateOnValidCoupon(code, price, amount, vat, total_price, discount_description);
        } else {
          this.updateOnInvalidCoupon(message);
        }

        this.resetCouponInput();
      });
  }

  updateOnValidCoupon(code, price, amount, vat, total_price, discount_description) {
    this.couponCodeTarget.value = code;
    this.showSummaryBox(this.selectedProduct);

    document.querySelectorAll('.discount-line').forEach(this.show);

    this.purchaseSummaryBoxTarget.querySelector("#product-price").innerHTML = price;
    this.purchaseSummaryBoxTarget.querySelector("#discount-price").innerHTML = amount;
    this.purchaseSummaryBoxTarget.querySelector("#discount-description").innerHTML = discount_description;
    this.purchaseSummaryBoxTarget.querySelector("#product-vat-price").innerHTML = vat;
    this.purchaseSummaryBoxTarget.querySelector("#product-total-price").innerHTML = total_price;

    this.clearDiscountErrorMessage();
  }

  updateOnInvalidCoupon(message) {
    this.couponCodeTarget.value = null;
    this.showSummaryBox(this.selectedProduct);

    this.couponErrorTarget.textContent = message;
    this.show(this.couponErrorTarget);

    document.querySelectorAll('.discount-line').forEach(this.hide);
  }

  clearDiscountErrorMessage() {
    this.couponErrorTarget.textContent = '';
    this.hide(this.couponErrorTarget);
  }

  resetCouponInput() {
    this.couponCodeTextTarget.value = '';
    this.couponCodeTextTarget.dispatchEvent(new Event('input', { bubbles: true }));
  }

  hide(element) {
    element.style.display = "none";
  }

  show(element) {
    element.style.display = "block";
  }

  enableProductInputs() {
    const inputs = document.querySelectorAll("input[name=product]");

    inputs.forEach((input) => {
      input.disabled = false;
    });
  }

  disableProductInputs() {
    const inputs = document.querySelectorAll("input[name=product]");

    inputs.forEach((input) => {
      input.disabled = true;
    });
  }

  companyHasNoStock() {
    const products = document.querySelectorAll("input[name=product]");
    return Array.from(products).every(p => p.dataset.linkStockNotAvailable === 'true');
  }

  showBillingInformationModalForm() {
    const element = document.getElementById("billingInformation");
    if(element) {
      this.enableProductInputs();
      this.productSelected({ currentTarget: this.findSelectedProduct() });
      new Modal(element).show();
    }
  }

  findSelectedProduct() {
    const products = document.querySelectorAll("input[name=product]");
    return Array.from(products).find(input => input.checked);
  }
}
